<template lang="pug">
  .row
    .col
      h5 Подтверждение приглашения

      form.form(@submit.prevent="doAccept")
        .form-group
          label(for="password") Пароль
          input.form-control(type="password" id="email" placeholder="Пароль" v-model="password" required)

        .form-group
          label(for="password_confirmation") Повторите пароль
          input.form-control(type="password" id="password_confirmation" placeholder="Пароль" v-model="password_confirmation" required)

        button.btn.btn-primary(type="submit") Сохранить
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    ...mapActions({
      acceptInvitation: 'acceptInvitation'
    }),
    doAccept () {
      const token = this.$route.query.invitation_token
      const params = {
        password: this.password,
        token: token
      }

      if (this.password !== this.password_confirmation) {
        alert('Пароли не совпадают')
        return false
      }

      if (this.password.length < 8) {
        alert('Пароль должен быть 8 символов или больше')
        return false
      }

      this.acceptInvitation(params).then(() => {
        this.$notify({
          title: 'Авторизация',
          type: 'success',
          text: 'Вы успешно авторизовались. Через несколько секунд Вы будете перемещены в свой кабинет'
        })

        window.location.href = '/'
      }).catch(() => {
        this.$notify({
          title: 'Авторизация',
          type: 'error',
          text: 'Ошибка при авторизации. Попробуйте позже'
        })
      })
    }
  }
}
</script>
